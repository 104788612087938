<template>
  <Header />
  <Footer />
  <div class="page-container q-mt-xl">
    <div>
      <h6 class="q-mt-xs q-mb-xs q-ml-md text-weight-bold text-left">
        {{ $t("BuyingProcess.transactions.past-transactions") }}
      </h6>
      <div class="transaction-page-container">
        <q-card class="q-my-md card-transactions" style="margin-bottom: 80px" flat>
          <!-- Botones Transacciones pasadas -->
          <q-item class="justify-end">
            <q-btn
              class="omni-btn-primary q-ma-sm active"
              unelevated
              :label="$t('BuyingProcess.transactions.all')"
              @click="showAllTransactions()"
              id="all-transactions"
            />
            <q-btn
              class="omni-btn-secondary q-ma-sm"
              unelevated
              :label="$t('BuyingProcess.transactions.expenses')"
              @click="showSubtractionTransactions()"
              id="subtraction-transactions"
            />
            <q-btn
              class="omni-btn-secondary q-ma-sm"
              unelevated
              :label="$t('BuyingProcess.transactions.incomes')"
              @click="showAdditionTransactions()"
              id="addition-transactions"
            />
          </q-item>
          <!-- Cada lista de transacciones está repetida y filtrada según el v-if-->
          <!-- All Transactions -->
          <div v-show="allTransactions">
            <q-list
              flat
              class="q-ma-xs"
              v-for="transaction in filterAll"
              :key="transaction.id"
            >
              <div v-if="filterAll.length > 0">
                <div v-if="transaction.action === 'addition' || 'subtraction'">
                  <q-item clickable v-ripple @click="showTransaction(transaction)">
                    <q-item-section avatar class="icon-section">
                      <q-icon
                        :class="
                          transaction.action === 'addition'
                            ? 'addition'
                            : 'subtraction'
                        "
                        :name="
                          transaction.action === 'addition'
                            ? 'arrow_forward'
                            : 'arrow_back'
                        "
                        size="20px"
                      />
                    </q-item-section>
                    <q-item-section
                      class="text-left text-weight-medium text-info-transaction omni-text-primary"
                      > {{
                          transaction.type === 'catalog_order'
                            ? 'Gift Order #' + transaction.id
                            : transaction.type
                        }}
                      <div class="text-left text-caption">
                        {{ transaction.created_at }}
                      </div>
                    </q-item-section>
                    <q-item-section
                      class="text-weight-medium text-right transaction-points omni-text-primary"
                    >
                      <span v-if="transaction.action === 'addition'">+</span
                      ><span v-else>-</span>
                      {{ transaction.points }}
                      {{ settings.currency_abbreviation }}</q-item-section
                    >
                  </q-item>
                </div>
              </div>
              <div v-else>{{ $t("General.no-data") }}</div>
            </q-list>
            <div
              class="q-pa-lg flex flex-center"
              v-if="transactions.points_records.length > 7"
            >
              <q-pagination
                v-model="pageAll"
                :min="currentPageAll"
                :max="numberPagination"
                :input="true"
              >
              </q-pagination>
            </div>
          </div>
          <!-- Subtraction Transactions -->
          <div v-show="subtractionTransactions">
            <q-list
              flat
              class="q-ma-xs"
              v-for="transaction in filterSubtraction"
              :key="transaction.id"
            >
              <div v-if="filterSubtraction.length > 0">
                <div v-if="transaction.action === 'subtraction'">
                  <q-item clickable v-ripple @click="showTransaction(transaction)">
                    <q-item-section avatar class="icon-section">
                      <q-icon
                        :class="
                          transaction.action === 'addition'
                            ? 'addition'
                            : 'subtraction'
                        "
                        :name="
                          transaction.action === 'addition'
                            ? 'arrow_forward'
                            : 'arrow_back'
                        "
                        size="20px"
                      />
                    </q-item-section>
                    <q-item-section
                      class="text-left text-weight-medium text-info-transaction omni-text-primary"
                      >{{ transaction.type }}
                      <div class="text-left text-caption">
                        {{ transaction.created_at }}
                      </div>
                    </q-item-section>
                    <q-item-section
                      class="text-weight-medium text-right transaction-points omni-text-primary"
      
                    >
                      <span v-if="transaction.action === 'addition'">+</span
                      ><span v-else>-</span>
                      {{ transaction.points }}
                      {{ settings.currency_abbreviation }}</q-item-section
                    >
                  </q-item>
                </div>
              </div>
              <div v-else>{{ $t("General.no-data") }}</div>
            </q-list>
            <div
              class="q-pa-lg flex flex-center"
              v-if="transactions.points_records.length > 7"
            >
              <q-pagination
                v-model="pageSubtraction"
                :min="currentPageSubtraction"
                :max="numberPaginationSubtraction"
                :input="true"
              >
              </q-pagination>
            </div>
          </div>
          <!-- Addition Transactions -->
          <div v-show="additionTransactions">
            <q-list
              flat
              class="q-ma-xs"
              v-show="additionTransactions"
              v-for="transaction in filterAddition"
              :key="transaction.id"
            >
              <div v-if="filterAddition.length > 0">
                <div v-if="transaction.action === 'addition'">
                  <q-item clickable v-ripple @click="showTransaction(transaction)">
                    <q-item-section avatar class="icon-section">
                      <q-icon
                        :class="
                          transaction.action === 'addition'
                            ? 'addition'
                            : 'subtraction'
                        "
                        :name="
                          transaction.action === 'addition'
                            ? 'arrow_forward'
                            : 'arrow_back'
                        "
                        size="20px"
                      />
                    </q-item-section>
                    <q-item-section
                      class="text-left text-weight-medium text-info-transaction omni-text-primary"
                      >{{ transaction.type }}
                      <div class="text-left text-caption">
                        {{ transaction.created_at }}
                      </div>
                    </q-item-section>
                    <q-item-section
                      class="text-weight-medium text-right transaction-points omni-text-primary"
                    >
                      <span v-if="transaction.action === 'addition'">+</span
                      ><span v-else>-</span>
                      {{ transaction.points }}
                      {{ settings.currency_abbreviation }}</q-item-section
                    >
                  </q-item>
                </div>
              </div>
              <div v-else>{{ $t("General.no-data") }}</div>
            </q-list>
            <div
              class="q-pa-lg flex flex-center"
              v-if="transactions.points_records.length > 7"
            >
              <q-pagination
                v-model="pageAddition"
                :min="currentPageAddition"
                :max="numberPaginationAddition"
                :input="true"
              >
              </q-pagination>
            </div>
          </div>
        </q-card>
        <q-card class="card-transactions-title q-ma-md omni-text-primary" flat>
          <q-card-section class="q-pt-sm text-left">
            {{ $t("General.balance") }}
            <p class="text-weight-bold text-h4 q-ma-xs">
              {{ points.points.points }}{{ settings.currency_abbreviation }}
            </p>
          </q-card-section>
        </q-card>
      </div>
    </div>

    <!-- Dialog Past Transaction show -->
    <PastTransactionsShow
      v-model="dialog"
      :transaction="openTransaction"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import PaginationPastTransactions from "../mixins/PaginationPastTransactionsMixin";
import PastTransactions from "../mixins/PastTransactionsMixins";
import settings from "@/mixins/settings";
import points from "@/mixins/points";

const PastTransactionsShow = defineAsyncComponent(() =>
  import("../components/PastTransactionsShow.vue")
);

const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

export default {
  mixins: [PaginationPastTransactions, PastTransactions, settings, points],
  components: {
    Header,
    Footer,
    PastTransactionsShow,
  },

  data() {
    return {
      dialog: false,
      openTransaction: null,
      allTransactions: true,
      additionTransactions: false,
      subtractionTransactions: false,
    };
  },
  computed: {
    transactions() {
      return this.$store.state.transactions.transactions;
    },

    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("transactions", ["getTransactions"]),
    checkOpenLastTransactionQueryParam() {
      if (Object.hasOwn(this.$route.query, "last")) {
        this.openTransaction = this.transactions.points_records.at(-1);
        if (this.openTransaction) {
          this.dialog = true;
        }
      }
      
    },
  },

  async created() {
    await this.getTransactions(this.user.id).then(() => {
      this.checkOpenLastTransactionQueryParam()
    });
  },
};
</script>

<style lang="scss" scoped>
.transaction-page-container {
  display: flex;

  @media only screen and (min-width: 1000px) {
    flex-direction: row;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
}

.card-transactions-title {
  border-radius: 25px 25px 25px 0px;
  box-shadow: 0px 4px 8px 0px #00000026;
  color: #282828;
  width: fit-content;
  height: fit-content;
}

.card-transactions {
  color: #282828;

  @media only screen and (min-width: 765px) {
    width: 600px;
  }

  @media only screen and (max-width: 765px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 600px) {
  .list-transactions {
    margin-bottom: 80px;
  }
}

.q-card-section {
  box-shadow: 0px 4px 8px 0px #00000026;
}

.addition {
  color: v-bind("settings.color_primary");
}

.subtraction {
  color: v-bind("settings.color_accent");
}

.text-info-transaction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  min-width: 150px;
}

.active {
  color: v-bind("settings.color_accent");
  border-color: v-bind("settings.color_accent");
}

.icon-section {
  min-width: 20px;
}

.transaction-points {
  flex-direction: row;
  justify-content: flex-end;
}
</style>
